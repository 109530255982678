<script setup>
import { watch, ref, onMounted } from 'vue'
import { useStore } from "vuex"
import _ from "lodash";

const route = useRoute()
const store = useStore()

const showRequestModal = computed(() => store.state.common.showRequestModal)

let checked = ref(false)

const hideModal = () => {
    store.commit('common/setRequestModalStatus', false)
}
</script>

<template>
    <div>
        <div class="modal" :class="{ active: showRequestModal }">
            <div class="modal__window" style="padding: 0;">
                <div class="modal__close" @click="hideModal()">
                    <span></span><span></span>
                </div>
                <div class="request-demo">
                    <div class="request-demo__bg" style="background-image: url(/img/request-bg.png);"></div>
                    <div class="request-demo__right">
                        <div class="request-demo__title">Request demo</div>
                        <div class="request-demo__subtitle">Use the form below to get in touch with the Geonomix team.</div>
                        <div class="request-demo__form">
                            <div class="request-demo__row">
                                <div class="request-demo__input">
                                    <span class="request-demo__input-title">Name</span>
                                    <label>
                                        <input type="text">
                                    </label>
                                </div>
                            </div>
                            <div class="request-demo__row">
                                <div class="request-demo__input">
                                    <span class="request-demo__input-title">Email</span>
                                    <label>
                                        <input type="text">
                                    </label>
                                </div>
                            </div>
                            <div class="request-demo__row">
                                <div class="request-demo__input">
                                    <span class="request-demo__input-title">Telephone</span>
                                    <label>
                                        <input type="text">
                                    </label>
                                </div>
                            </div>
                            <div class="request-demo__row">
                                <div class="request-demo__input">
                                    <span class="request-demo__input-title">Company name</span>
                                    <label>
                                        <input type="text">
                                    </label>
                                </div>
                            </div>
                            <div class="request-demo__row">
                                <div class="request-demo__input">
                                    <span class="request-demo__input-title">Message</span>
                                    <label>
                                        <textarea></textarea>
                                    </label>
                                </div>
                            </div>
                            <div class="request-demo__policy">
                                <div class="request-demo__check">
                                    <input
                                        type="checkbox"
                                        name="request-demo-policy"
                                        :id="`request-demo-policy`"
                                        class="checkbox"
                                        v-model="checked"
                                    >
                                    <label :for="`request-demo-policy`"></label>
                                </div>
                                <span>I agree to the processing of <a href="#">personal data</a></span>
                            </div>
                            <div class="request-demo__footer">
                                <div class="request-demo__button button button--default">Send a Message</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.request-demo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 300px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__right {
        padding: 30px;
        margin-left: 300px;
    }
    &__title {
        color: #14223D;
        font-size: 24px;
        font-weight: 700;
        line-height: 120%; 
        margin-bottom: 25px;
    }
    &__subtitle {
        color: #14223D;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px; 
        margin-bottom: 10px;
    }
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    &__input-title {
        color: #7185AC;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; 
        margin-right: 25px;
        margin-top: 4px;
        flex: 1;
    }
    &__input {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        label {
            width: 280px;
        }
        input, textarea {
            width: 100%;
            border: 1px solid #F7F7F9;
            background: #F7F7F9;
            margin: 0;
            padding: 0;
            border: none;
            outline: none;
            resize: none;
            color: var(--black-text, #14223D);
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            padding: 6px 10px;
        }
        input {
            height: 28px;
            border-radius: 30px;
        }
        textarea {
            height: 84px;
            border-radius: 14px;
        }
    }
    &__policy {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 25px;
        color: #14223D;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; 
        a {
            color: #117EC2;
            text-decoration: none;
        }
    }
    &__check {
        position: relative;
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}
</style>