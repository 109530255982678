<script setup>
import { ref, nextTick, computed, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const notification = computed(() => store.state.common.notification)
const isNotificationActive = computed(() => store.state.common.notification.isActive)

watch(isNotificationActive, (active) => {
    if (active) {
        setTimeout(() => {
            store.commit('common/setNotification', {
                isActive: false
            })
        }, 3000)
    }
})
</script>

<template>
    <div class="notifications">
        <div class="notifications__content" :class="{ active: isNotificationActive }">
            <div class="notifications__text" :style="[ notification.text.includes('rror') ? { 'color': '#F35252' } : { 'color': 'var(--primary-color)' } ]">{{ notification.text }}</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.notifications {
    position: fixed;
    right: -30rem;
    top: 0;
    z-index: 4;
    height: 100vh;
    display: flex;
    &__content {
        position: absolute;
        top: 15rem;
        right: 0;
        max-width: 400rem;
        width: max-content;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        border-radius: 12rem;
        transition: transform 0.2s ease-in;
        padding: 10rem 15rem;
        box-shadow: 0rem 1rem 6rem rgb(0 0 0 / 60%);
        background: white;
        opacity: 0;
        visibility: hidden;
        z-index: 0;
        transform: translateX(0rem);
        &.active {
            opacity: 1;
            visibility: visible;
            z-index: 1;
            transform: translateX(-45rem);
        }
    }
    &__text {
        line-height: 18rem;
        color: var(--primary-color);
        font-size: 14rem;
    }
}
</style>